
import {useUserInfoStore} from "@/stores/userInfo";
import router from "@/router";
import Masquerade from "@/components/Masquerade/Masquerade";
import {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBAlert
} from 'mdb-vue-ui-kit';
import { ref } from 'vue';
import {storeToRefs} from "pinia/dist/pinia";
import axios from "axios";
import { loadScript } from "vue-plugin-load-script";
loadScript("https://kit.fontawesome.com/662d1841d0.js")
    .then(() => {
        // Script is loaded, do something
    })
    .catch(() => {
        // Failed to fetch script
    });

export default {
    name: 'mainMenu,',
    components: {
        MDBNavbar,
        MDBNavbarToggler,
        MDBNavbarNav,
        MDBNavbarItem,
        MDBCollapse,
        MDBDropdown,
        MDBDropdownToggle,
        MDBDropdownMenu,
        MDBDropdownItem,
        MDBAlert,
        Masquerade
    },

    setup() {
        const collapse5 = ref(false);
        const dropdown1 = ref(false);
        const dropdown2 = ref(false);
        const dropdown3 = ref(false);
        const dropdown4 = ref(false);
        const dropdown5 = ref(false);

        const userInfoStore = useUserInfoStore()
        const alert = ref(false);
        const alertColor = ref("success");
        const alertMessage = ref("");
        const showNotification = (type, message) => {
            alertMessage.value = `<strong>${message}</strong>`;
            alertColor.value = type;
            alert.value = true;
        }
        const { admin } =  storeToRefs(userInfoStore)
        const { orgType } =  storeToRefs(userInfoStore)
        const { currentProfileId } =  storeToRefs(userInfoStore)
        const { currentProfileHexCode } =  storeToRefs(userInfoStore)

        const instruments = ref([])
        const showProducerVendorSalesSlips = ref(false)

        const basePath = process.env.VUE_APP_BASE_PATH

        return {
            collapse5,
            dropdown1,
            dropdown2,
            dropdown3,
            dropdown4,
            dropdown5,
            basePath,
            admin,
            orgType,
            alert,
            alertColor,
            alertMessage,
            showNotification,
            userInfoStore,
            instruments,
            currentProfileId,
            currentProfileHexCode,
            showProducerVendorSalesSlips
        }
    },
    computed: {
        loggedIn() {
            return !!this.userInfoStore.currentUserId;
        }
    },
    mounted() {
        this.loadData()
    },
    watch: {
        currentProfileId() {
            this.loadData()
        },
        currentProfileHexCode() {
            this.loadData()
        },
    },
    methods: {
        logout() {
            this.userInfoStore.$reset()
            router.push(process.env.VUE_APP_BASE_PATH + "/login")
        },
        loadData() {
            window.setTimeout(() => {
                if (this.currentProfileHexCode !== null) {
                    axios.get(process.env.VUE_APP_API_DOMAIN + "/metric_selections/instruments_selected/" + this.currentProfileHexCode)
                        .then(response => {
                            console.log(response)
                            console.log(response.data)
                            if (response.data == "null" || response.data == null) {
                                this.instruments = ["Vendor Application", "Visitor Count", "Visitor Survey", "Vendor Sales Slips and Central Terminal Sales & Tokens", "Vendor Attendance", "Volunteers", "Market Programs", "Ecosystem Services"]
                            } else {
                                console.log("NOT NULL!")
                                this.instruments = JSON.parse(response.data)
                            }
                            if (this.instruments === null) {
                                this.instruments = ["Vendor Application", "Visitor Count", "Visitor Survey", "Vendor Sales Slips and Central Terminal Sales & Tokens", "Vendor Attendance", "Volunteers", "Market Programs", "Ecosystem Services"]
                            }

                        })
                        .catch(error => {
                            //this.$root.handleHttpError(error)
                            console.log(error)
                            this.instruments = ["Vendor Application", "Visitor Count", "Visitor Survey", "Vendor Sales Slips and Central Terminal Sales & Tokens", "Vendor Attendance", "Volunteers", "Market Programs", "Ecosystem Services"]
                            this.loadingOrgProfiles = false
                        })
                }
            }, 500)



            if (this.userInfoStore.isSPCSP) {
                this.showProducerVendorSalesSlips = true
            } else {
                window.setTimeout(() => {
                    axios.get(process.env.VUE_APP_API_DOMAIN + "/profiles/producer_part_of_market/" + this.currentProfileId)
                    .then(response => {
                        console.log("market profile ids response")
                        console.log(response)
                        this.showProducerVendorSalesSlips = response.data
                    })
                    .catch(error => {
                        //this.$root.handleHttpError(error)
                        this.loadingOrgProfiles = false
                        console.log(error)
                    })

                /*window.setTimeout(() => {
                  if (this.userInfoStore.masquerading) {
                    this.showNotification('warning', "<i class=\"fas fa-exclamation-triangle me-3\"></i><span style='font-weight:500'>You are currently masquerading as " + this.userInfoStore.name + " (User ID " + this.userInfoStore.currentUserId + ").    To stop masquerading, click <a href='" + process.env.VUE_APP_BASE_PATH  + "/masquerade/0' class=\"alert-link\">here</a>.</span>" )
                  }
                }, 1000);*/
                }, 500)
            }
        }
    }
};